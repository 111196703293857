import React from 'react';
import github from './../img/github.png';
import globe from './../img/globe.png';
import { Link } from 'gatsby';
import { CssSyntaxError } from 'postcss';


const card = ( props ) => {
    return (
        <div class="container w-100 h-screen laptop:w-72 laptop:h-96 desktop:w-80">
            <div class="container h-100 bg-gray-dark">
                <img class="scale-125" src={props.backImg}></img>
            </div>
            <div class="container h-1/3 bg-gray-darkest">
                <h1 class="text text-5xl font-bold px-3 py-2 laptop:text-2xl">
                    {props.title}
                </h1>
                <p class="text text-sm px-3">
                    {props.description}
                </p>
            </div>
            <div class="h-14 bg-black flex items-center">
                <Link to={props.link} class="w-1/2 flex justify-evenly items-center h-14 hover:bg-black-light transition duration-100 ease-in">
                    <img src={github} alt="github icon" class="w-9 opacity-90"></img>
                    <p class="text-gray-lightest font-bold text-sm">VIEW GITHUB</p>
                </Link>
                <Link to={props.link2} class={props.css}>
                    <img src={globe} alt="link to demo" class="w-10"></img>
                    <p class="text-black font-bold text-sm">VIEW DEMO</p>
                </Link>
            </div>
        </div>
    )
}

export default card
