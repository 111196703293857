import React from 'react'
import Nav from '../component/nav'
import Card from '../component/card'
import FadeIn from 'react-fade-in';
import binaryBg from './../images/binaryPractice.png';
import spotBg from './../images/spotify.png';
import pokeBG from './../images/pokemon.png';

const projects = () => {
    return (
        <div class="container mx-auto">
            <Nav />
            <FadeIn>
                <h1 class="text text-4xl font-bold px-10 py-10 text-left laptop:text-5xl">Projects</h1>
                <div class="block mx-10 my-6 laptop:flex laptop:justify-between">
                    <Card link='https://github.com/kzmbo/binary-practice' link2="https://zen-morse-013f5b.netlify.app/" backImg={binaryBg} title="Binary Practice" description="A web app for CS students that want to practice their converting skills between binary, decimal, and hexadecimal" css="w-1/2 h-14 flex justify-evenly items-center bg-yellow hover:bg-yellow-light transition duration-100 ease-in"/>
                    <Card link='https://github.com/kzmbo/sortify' link2="https://devpost.com/software/spotcleaning" backImg={spotBg} title="SpotCleaner" description="2022 BeachHacks Submission! A web app where Spotify users can add multiple songs, merge playlists, and remove songs by attributes." css="w-1/2 h-14 flex justify-evenly items-center bg-green hover:bg-green-light transition duration-100 ease-in"/>
                    <Card link='https://github.com/kzmbo/project_2' link2="https://github.com/kzmbo/project_2" backImg={pokeBG} title="Pokémon" description="A Java console application where players can play a text-based version of Pokémon. Utilized OOP to desigin and develop this game." css="w-1/2 h-14 flex justify-evenly items-center bg-pink hover:bg-pink-light transition duration-100 ease-in"/>
                </div> 
            </FadeIn>
        </div> 
    )
}

export default projects
